import React from "react";
import { Spin } from "antd";

function Loader() {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255,255,255, 0.80)",
        top: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" tip="Solo un attimo..."></Spin>
    </div>
  );
}

export default Loader;
