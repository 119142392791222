import React, { useState, useEffect } from "react";

import axios from "axios";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { Link, navigate } from "gatsby";
import {
  Input,
  Form,
  Button,
  PageHeader,
  Descriptions,
  Layout,
  InputNumber,
  Result,
  Space,
} from "antd";
import Loader from "../../components/loader";

function Valutazione({ location }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [allData, setAllData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [finito, setFinito] = useState(false);

  let data = allData[currentIndex];

  function vota(values) {
    console.log(values);
    axios
      .post(
        "https://concorsopaoloferro.edu.it/concorso-api/dashboard/vota-offline.php",
        {
          id: data.id,
          voto: values.voto,
        }
      )
      .then((result) => {
        console.log(result.data);
        if (!allData[currentIndex + 1]) {
          setFinito(true);
        } else {
          setCurrentIndex(currentIndex + 1);
        }
      });
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    console.log(id);
    axios
      .post(
        "https://concorsopaoloferro.edu.it/concorso-api/dashboard/offline-iscritto.php",
        {
          id: id,
        }
      )
      .then((result) => {
        console.log(result.data);
        setAllData(result.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setLoading(false);
      });
  }, []);

  if (loading) return <Loader />;

  if (error) return <div>Lo studente non esiste</div>;

  const programmaArray = JSON.parse(data.programma);

  if (finito)
    return (
      <Result
        status="success"
        title="Finito!"
        subTitle="Tutti i partecipanti hanno ricevuto il loro voto"
        extra={[
          <Button
            onClick={() => navigate("/dashboard")}
            type="primary"
            key="console"
          >
            Dashboard
          </Button>,
          <Button key="buy" onClick={() => window.history.back()}>
            Tabella
          </Button>,
        ]}
      />
    );

  return (
    <>
      <PageHeader
        style={{
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        }}
        ghost={false}
        onBack={() => window.history.back()}
        title="Torna all'elenco"
        extra={[
          <Button
            disabled={!allData[currentIndex + 1] ? true : false}
            key="1"
            type="primary"
            onClick={() => {
              setCurrentIndex(currentIndex + 1);
            }}
          >
            Salta
          </Button>,
        ]}
      ></PageHeader>
      <div id="valutazione-w">
        <div id="valutazione-content">
          <main>
            {data.nome ? (<h1>{data.nome + " " + data.cognome}</h1>) : (<h1>{data.nomeGruppo}</h1>)}
            <div>
              <Form
                layout="vertical"
                style={{ textAlign: "center" }}
                onFinish={vota}
              >
                <h2 style={{ marginTop: 40 }}>Valutazione</h2>
                <Space>
                  <Form.Item name="voto">
                    <InputNumber
                      min={0}
                      max={100}
                      placeholder={data.voto}
                      required
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Invia
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </div>
          </main>
        </div>
        <aside>
          <div>
            <h3>Programma</h3>
            {programmaArray.map((item) => {
              return <p key={item}>{"- " + item}</p>;
            })}
            <h3>Minutaggio</h3>
            <p>{data.minutaggioTotale}</p>
            <h3>Scuola</h3>
            <p>{data.istituto}</p>
            <h3>Email insegnante</h3>
            <p>{data.emailInsegnante}</p>
            <h3>Email genitore</h3>
            <p>{data.emailGenitore}</p>
          </div>
        </aside>
      </div>
    </>
  );
}

export default Valutazione;
